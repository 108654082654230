<template>
  <section>
    <kn-table 
      :headers="headers" 
      :items="items"
      :loading="loading"
      :showDownloadBtn="true"
      :loadingDownload="loadingDownload"
      tableTitle="Resumen de ingresos" 
      newButtonLabel="Agregar ingreso"
      downloadTitle="Descargar reporte de ingresos"
      @action="openAlert"
      @downloadAction="downloadExcelIncome"
    />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loadingAlert"
      textBtn2="Aceptar"
      @action1="actionAlertBtn1"
      @action2="actionAlertBtn2"
    />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { apiMixin } from '@/mixins/apiMixin'
import { api } from "@/api/axios-base";
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import KnTable from '@/components/tables/KnTable.vue'
import KnLocalAlert from '../../components/KnLocalAlert.vue'
export default {
  components: { KnTable, KnLocalAlert },
  mixins: [apiMixin, fileUtilsMixin],
  data() {
    return {
      headers: [
        { text: 'Fecha', value: 'fecha', class: 'purple--text' },
        { text: 'Alumno', value: 'nombre_alumno', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'subtotal', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuento', value: 'descuento', class: 'purple--text' },
        { text: 'Total', value: 'total', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      incomes: [],
      items: [],
      search: null,
      loading: true,
      itemToEdit: null,
      loadingDownload: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Estas a punto de "Corregir" un ingreso. Deseas continuar?',
      alertColor: 'error',
      /*********************** */
    }
  },
  computed: {
    ...mapState(['institutionId']),
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.incomes = await this.fetchResultsByEI('administracion', 'ingreso', this.institutionId)
    await this.getIncomes()
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getIncomes() {
      try {
        for (const income of this.incomes) {
          let fullName = 'Sin alumno'
          // console.log('Alumno?: ', income.alumno);
          if (income.alumno !== null) {
            const student = {...income.alumno}
            const personalInfo = await this.fetchObjectById('personas', 'datos-personales-all', student.datos_personales)
            const {
              primer_nombre: pn,
              segundo_nombre: sn,
              apellido_paterno: ap,
              apellido_materno: am
            } = personalInfo
            fullName = `${pn} ${sn} ${ap} ${am}`
          }
          // console.log('Nombre completo alumno', fullName);
          const item = {
            folio: income.id,
            fecha: income.fecha_elaboracion.substring(0, 10),
            nombre_alumno: fullName,
            concepto: income.categoria.dato,
            subtotal: income.sub_total,
            descuento: income.total_descuento,
            total: income.total_ingreso,
            ...income
          }
          this.items.push(item)
        }
      } catch (error) {
        console.error('Error al obtener ingresos.', error);
      }
    },
    openAlert(obj) {
      console.log('Open modal', obj);
      this.itemToEdit = this.items.find(i => i.id === obj.itemId)
      this.showAlert = true
    },
    actionAlertBtn1() {
      this.closeAlert()
    },
    actionAlertBtn2() {
      this.showAlert = false
      this.$router.push({
        name: 'Editar Ingresos',
        params: {
          entity: this.itemToEdit,
          readonly: false
        }
      })
    },
    closeAlert() {
      this.itemToEdit = null
      this.showAlert = false
    },
    async downloadExcelIncome () {
      try {
        this.loadingDownload = true
        const fileName = 'ingresos.xls'
        const response = await api.get('/administracion/reporte-ingresos', {
          responseType: 'blob'
        })
        const responseData = await response.data
        console.log("Blob?", responseData);
        if (response.status === 200) {
          this.downloadFileFromBlob(responseData, fileName)
        }
      } catch (error) {
        console.error("Ocurrión un error al intentar descargar el reporte de ingresos")
      } finally {
        this.loadingDownload = false
      }
    }
  }
}
</script>

<style>
.border {
  border-color: gray;
  border-style: solid;
  border-width: 1px;
}
</style>